<template>
  <section class="purchase-add-wrapper">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmitSave)"
        @reset.prevent="resetForm"
      >
        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >

              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column purchase-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        swm
                      </h3>
                    </div>
                    <!-- <b-card-text class="mb-25">
                      Office 149, 450 South Brand Brooklyn
                    </b-card-text>
                    <b-card-text class="mb-25">
                      San Diego County, CA 91905, USA
                    </b-card-text>
                    <b-card-text class="mb-0">
                      +1 (123) 456 7891, +44 (876) 543 2198
                    </b-card-text> -->
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-right justify-content-md-end mb-1">
                      <h4 class="">
                        {{ t('Приход товара') }}
                      </h4>
                      <!-- <b-input-group class="input-group-merge purchase-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="purchase-data-id"
                          v-model="purchaseData.id"
                          disabled
                        />
                      </b-input-group> -->
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                      <span class="title align-middle mb-1">
                        {{ t('Date') }}:
                      </span>
                      <validation-provider
                        #default="validationContext"
                        :name="t('Date')"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          class="invoice-edit-input-group"
                        >
                          <flat-pickr
                            v-model="purchaseData.date"
                            class="form-control invoice-edit-input"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <!-- <div class="d-flex align-items-center">
                      <span class="title">
                        Due Date:
                      </span>
                      <flat-pickr
                        v-model="purchaseData.dueDate"
                        class="form-control purchase-edit-input"
                      />
                    </div> -->
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">

                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Supplier') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Supplier')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select supplier -->
                        <v-select
                          ref="refSupplierOpt"
                          v-model="purchaseData.supplier"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="suppliers"
                          label="firstname"
                          input-id="purchase-data-client"
                          :clearable="false"
                        >
                          <template #list-footer>
                            <li
                              v-b-toggle.sidebar-purchase-add-new-supplier
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new supplier') }}</span>
                            </li>
                            <li
                              v-if="suppliers.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ firstname, surname, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ `${firstname} ${surname}` }}</span>
                              <b-badge
                                v-if="defaults === '1'"
                                pill
                                :variant="`light-primary`"
                              >
                                <small>{{ t('Default') }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ firstname, surname, defaults, balance }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <div class="align-items-center">
                                <span class="align-middle"> {{ `${firstname} ${surname}` }}</span>
                                <b-badge
                                  v-if="defaults === '1'"
                                  pill
                                  :variant="`light-primary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge>
                              </div>
                              <b-badge
                                pill
                                :variant="`light-${resolveBalanceColor(balance)}`"
                                class="mr-55"
                              >
                                <small>{{ t('Balance') }}: </small>
                                <!-- {{ label }} -->
                                {{ roundUp(balance) }}
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- Selected supplier -->
                    <div
                      v-if="purchaseData.supplier"
                      class="mt-1"
                    >
                      <li class="d-flex justify-content-between align-items-end">
                        <b-badge
                          pill
                          :variant="`light-${resolveBalanceColor(purchaseData.supplier.balance)}`"
                        >
                          <small>{{ t('Balance') }}: </small>
                          <!-- {{ label }} -->
                          {{ roundUp(purchaseData.supplier.balance) }}
                        </b-badge>
                      </li>
                      <!-- <h6 class="mb-25">
                        {{ purchaseData.supplier.firstname }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ purchaseData.supplier.surname }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ purchaseData.supplier.address }}, {{ purchaseData.supplier.city }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ purchaseData.supplier.phone }}
                      </b-card-text>
                      <b-card-text class="mb-0">
                        {{ purchaseData.supplier.email }}
                      </b-card-text> -->
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Warehouse') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Warehouse')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select warehouse -->
                        <v-select
                          ref="refWarehouseOpt"
                          v-model="purchaseData.warehouse"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="warehouses"
                          label="name"
                          input-id="purchase-data-client"
                          :clearable="false"
                          @input="val => selectWarehouse(val)"
                        >
                          <template #selected-option="{ name, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="defaults === '1'"
                                pill
                                :variant="`light-primary`"
                              >
                                <small>{{ t('Default') }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ name, type, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <div>
                                <span class="align-middle"> {{ name }}</span>
                                <b-badge
                                  v-if="defaults === '1'"
                                  pill
                                  :variant="`light-secondary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge>
                              </div>
                              <b-badge
                                v-if="type !== null"
                                pill
                                :variant="`light-secondary`"
                                class="mr-55"
                              >
                                <small>{{ t(type) }}</small>
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected warehouse -->
                    <div
                      v-if="purchaseData.warehouse"
                      class="mt-1"
                    >
                      <!-- <li class="d-flex justify-content-between align-items-end">
                        <b-badge
                          pill
                          :variant="`light-${resolveBalanceColor(Math.floor(Math.random()*15)*1000000-3000000)}`"
                        >
                          <small>{{ t('Ostatka') }}: </small>
                          {{ `${Math.floor(Math.random()*15)*1000000-3000000} ${ t('t') }` }}
                        </b-badge>
                      </li> -->
                      <!-- <h6 class="mb-1">
                        {{ t('Ostatka') }}:
                      </h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Product1:
                            </td>
                            <td><span class="font-weight-bold">110.55</span></td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Product2:
                            </td>
                            <td><span class="font-weight-bold">10.55</span></td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Car') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Car')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select warehouse -->
                        <v-select
                          ref="refCarOpt"
                          v-model="purchaseData.car"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="cars"
                          label="name"
                          input-id="purchase-data-client"
                          taggable
                          push-tags
                          :clearable="false"
                        >
                          <template #list-footer>
                            <li
                              v-b-toggle.sidebar-purchase-add-new-car
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add New Car') }}</span>
                            </li>
                            <li
                              v-if="cars.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected warehouse -->
                    <!-- <div
                      v-if="purchaseData.car"
                      class="mt-1"
                    >
                      <h6 class="mb-25">
                        {{ purchaseData.car.name }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ purchaseData.car.number }}
                      </b-card-text>
                    </div> -->
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Attachment') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Attachment')"
                      rules=""
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-file
                          ref="refFile"
                          v-model="purchaseData.file_id"
                          :state="getValidationState(validationContext)"
                          :placeholder="t('Choose a file or drop it here')"
                          :drop-placeholder="t('Drop file here')"
                          @input="val => fileSubmit(val)"
                        >
                          <template
                            slot="file-name"
                            slot-scope="{ names }"
                          >
                            <b-badge variant="primary">
                              {{ names[0] }}
                            </b-badge>
                            <b-spinner
                              v-if="submitOnProcess.file"
                              class="align-middle ml-25"
                              small
                              type="grow"
                            />
                          </template>
                        </b-form-file>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected warehouse -->
                    <!-- <div
                      v-if="purchaseData.car"
                      class="mt-1"
                    >
                      <h6 class="mb-25">
                        {{ purchaseData.car.name }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ purchaseData.car.number }}
                      </b-card-text>
                    </div> -->
                  </b-col>

                  <!-- Col: Payment Details -->
                  <!-- <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                    <div>
                      <h6 class="mb-2">
                        Payment Details:
                      </h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Total Due:
                            </td>
                            <td><span class="font-weight-bold">$12,110.55</span></td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Bank name:
                            </td>
                            <td>American Bank</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Country:
                            </td>
                            <td>United States</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              IBAN:
                            </td>
                            <td>ETD95476213874685</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              SWIFT code:
                            </td>
                            <td>BR91905</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col> -->
                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in purchaseData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            {{ t('Product') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Cost') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Qty') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Weight') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ t('Price') }}
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 px-2 pt-2 pb-0">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Product')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                              >
                                <label class="d-inline d-lg-none">{{ t('Product') }}</label>
                                <v-select
                                  ref="refProductOpt"
                                  v-model="item.product"
                                  :state="getValidationState(validationContext)"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="products"
                                  label="itemTitle"
                                  :clearable="false"
                                  class="mb-2 item-selector-title"
                                  @input="val => updateItemForm(index, val)"
                                >
                                  <template #list-footer>
                                    <li
                                      v-b-toggle.sidebar-purchase-add-new-product
                                      class="add-new-client-header d-flex align-items-center my-50"
                                    >
                                      <feather-icon
                                        icon="PlusIcon"
                                        size="16"
                                      />
                                      <span class="align-middle ml-25">{{ t('Add new product') }}</span>
                                    </li>
                                    <li
                                      v-if="products.length === 0"
                                      class="text-center text-primary my-2"
                                    >
                                      <b-spinner
                                        class="align-middle mr-1"
                                      />
                                      <strong>Загрузка...</strong>
                                    </li>
                                  </template>
                                  <template #selected-option="{ itemTitle }">
                                    <!-- <feather-icon
                                      :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                      size="16"
                                      class="align-middle mr-50"
                                    /> -->
                                    <li class="d-flex justify-content-between align-items-center">
                                      <span class="align-middle mr-50"> {{ itemTitle }}</span>
                                      <!-- <b-badge
                                        v-if="product.id === 2"
                                        pill
                                        :variant="`light-primary`"
                                      >
                                        <small>{{ t('Default') }}</small>
                                      </b-badge> -->
                                    </li>
                                  </template>
                                  <template #option="{ itemTitle, weight }">
                                    <!-- <feather-icon
                                      :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                      size="16"
                                      class="align-middle mr-50"
                                    /> -->
                                    <li class="d-flex justify-content-between align-items-center">
                                      <div class="align-items-center">
                                        <span class="align-middle"> {{ itemTitle }}</span>
                                        <!-- <b-badge
                                          v-if="product.id === 2"
                                          pill
                                          :variant="`light-primary`"
                                        >
                                          <small>{{ t('Default') }}</small>
                                        </b-badge> -->
                                      </div>
                                      <b-badge
                                        pill
                                        :variant="`light-${resolveBalanceColor(weight)}`"
                                        class="pr-60"
                                      >
                                        <!-- {{ label }} -->
                                        {{ weight }}
                                      </b-badge>
                                    </li>
                                  </template>
                                </v-select>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Cost')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                              >
                                <label class="d-inline d-lg-none">{{ t('Cost') }}</label>
                                <b-form-input
                                  v-model="item.cost"
                                  :state="getValidationState(validationContext)"
                                  type="number"
                                  class="mb-2"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Qty')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                              >
                                <label class="d-inline d-lg-none">{{ t('Qty') }}</label>
                                <b-form-input
                                  v-model="item.quantity"
                                  :state="getValidationState(validationContext)"
                                  type="number"
                                  class="mb-2"
                                  @input="val => updateSubItemQty(index, val)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="t('Weight')"
                              rules="required"
                            >
                              <b-form-group
                                :state="getValidationState(validationContext)"
                              >
                                <label class="d-inline d-lg-none">{{ t('Weight') }}</label>
                                <b-form-input
                                  :id="`form-item-settings-icon-${index}`"
                                  v-model="item.weight"
                                  :state="getValidationState(validationContext)"
                                  type="number"
                                  class="mb-2"
                                  readonly
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <b-form-group>
                              <label class="d-inline d-lg-none">{{ t('Price') }}</label>
                              <b-form-input
                                :value="roundUpCustom(item.cost*item.weight)"
                                type="text"
                                class="mb-2"
                                readonly
                              />
                            </b-form-group>

                          </b-col>
                          <!-- <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">{{ t('Description') }}</label>
                            <b-form-textarea
                              v-model="item.description"
                              class="mb-2 mb-lg-0"
                            />
                          </b-col> -->
                          <!-- <b-col>
                            <p class="mb-0">
                              Discount: 0% 0% 0%
                            </p>
                          </b-col> -->
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                          <!-- Setting Item Form -->
                          <b-popover
                            :ref="`form-item-settings-popover-${index}`"
                            :target="`form-item-settings-icon-${index}`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <!-- Field: Discount -->
                                <b-col
                                  cols="12"
                                  class="mb-1"
                                >
                                  <div class="d-flex justify-content-between align-items-center">
                                    <label for="totalWeightActivator">{{ t('Total weight') }}</label>
                                    <b-form-checkbox
                                      id="totalWeightActivator"
                                      v-model="totalWeightActivator"
                                      switch
                                    />
                                  </div>
                                </b-col>
                                <b-col
                                  v-if="totalWeightActivator"
                                  cols="12"
                                >
                                  <b-form-input
                                    :id="`setting-subitem-${index}-weight`"
                                    v-model="item.weight"
                                    type="number"
                                    placeholder="Weight"
                                    class="mb-1"
                                  />
                                </b-col>
                                <b-col
                                  v-if="!totalWeightActivator"
                                  cols="12"
                                >
                                  <b-form-input
                                    v-for="(subItem, i) in item.subItems"
                                    :id="`setting-subitem-${index}-weight`"
                                    :key="i"
                                    v-model="subItem.weight"
                                    type="number"
                                    :placeholder="`Weight ${i+1}`"
                                    class="mb-1"
                                  />
                                </b-col>

                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-between mt-1"
                                >
                                  <b-button
                                    variant="outline-primary"
                                    @click="() => {onSubmitWeight(index); $refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                  >
                                    Apply
                                  </b-button>
                                  <b-button
                                    variant="outline-secondary"
                                    @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                  >
                                    Cancel
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  {{ t('Add Item') }}
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <!-- <label
                      for="purchase-data-sales-person"
                      class="text-nowrap mr-50"
                    >Sales Person:</label>
                    <b-form-input
                      id="purchase-data-sales-person"
                      v-model="purchaseData.salesPerson"
                      placeholder="Edward Crowley"
                    /> -->
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <!-- <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          $1800
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Discount:
                        </p>
                        <p class="invoice-total-amount">
                          $28
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Tax:
                        </p>
                        <p class="invoice-total-amount">
                          21%
                        </p>
                      </div> -->
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ t('Subtotal') }}:
                        </p>
                        <p class="invoice-total-amount text-nowrap">
                          {{ roundUpCustom(parseFloat(totalPrice), 2) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ t('Extra costs') }}:
                        </p>
                        <p class="invoice-total-amount text-nowrap">
                          {{ roundUpCustom(parseFloat(purchaseData.extra_costs.amount ? purchaseData.extra_costs.amount : 0), 2) }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ t('Total') }}:
                        </p>
                        <p class="invoice-total-amount text-nowrap">
                          {{ roundUpCustom(parseFloat(totalPrice) + parseFloat(purchaseData.extra_costs.amount ? purchaseData.extra_costs.amount : 0), 2) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">{{ t('Note') }}: </span>
                <b-form-textarea v-model="purchaseData.note" />
              </b-card-body>
            </b-card>

          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >

            <!-- Action Buttons -->
            <b-card>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft || submitOnProcess.file"
                @click.prevent="handleSubmit(onSubmitSave)"
              >
                <b-spinner
                  v-if="submitOnProcess.save"
                  small
                  type="grow"
                />
                {{ t('Save') }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft || submitOnProcess.file"
                @click.prevent="handleSubmit(onSubmitSaveSell)"
              >
                <b-spinner
                  v-if="submitOnProcess.saveandsell"
                  small
                  type="grow"
                />
                {{ t('Save and sell') }}
              </b-button>

              <!-- Button: Draft -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft || submitOnProcess.file"
                @click.prevent="handleSubmit(onSubmitToDraft)"
              >
                <b-spinner
                  v-if="submitOnProcess.todraft"
                  small
                  type="grow"
                />
                {{ t('To draft') }}
              </b-button>

              <!-- Button: Cancel -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft || submitOnProcess.file"
                :to="{ name: 'apps-purchase-list'}"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-card>

            <!-- Payment Method -->
            <div
              class="mt-2"
            >
              <!-- extra costs -->
              <div class="d-flex justify-content-between align-items-center my-1">
                <label for="extraCosts">{{ t('Extra costs') }}</label>
                <b-form-checkbox
                  id="extraCosts"
                  v-model="purchaseData.toggler"
                  switch
                />
              </div>

              <div class="align-items-center my-1">
                <validation-provider
                  v-show="purchaseData.toggler"
                  #default="validationContext"
                  :name="t('Kassa')"
                  :rules="purchaseData.toggler ? 'required' : ''"
                >
                  <b-form-group
                    :label="t('Kassa')"
                    label-for="kassa"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="purchaseData.extra_costs"
                      :state="getValidationState(validationContext)"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="kassa"
                      :options="accounts"
                      input-id="kassa"
                      class="payment-selector"
                      :clearable="false"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="align-items-center my-1">
                <validation-provider
                  v-show="purchaseData.toggler"
                  #default="validationContext"
                  :name="t('Amount')"
                  :rules="purchaseData.toggler ? 'required' : ''"
                >
                  <b-form-group>
                    <label>{{ t('Amount') }}</label>
                    <b-form-input
                      v-model="purchaseData.extra_costs.amount"
                      :state="getValidationState(validationContext)"
                      type="number"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>

              <!-- Payment Stub -->
              <!-- <div class="d-flex justify-content-between align-items-center">
                <label for="paymentStub">Payment Stub</label>
                <b-form-checkbox
                  id="paymentStub"
                  switch
                />
              </div> -->
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <purchase-sidebar-add-new-suplier
      @refetch-data="refetchSuppliers"
    />
    <purchase-sidebar-add-new-warehouse
      @refetch-data="refetchWarehouses"
    />
    <purchase-sidebar-add-new-car
      @refetch-data="refetchCars"
    />
    <purchase-sidebar-add-new-product
      @refetch-data="refetchProducts"
    />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import router from '@/router'
import {
  ref, onUnmounted, watchEffect, computed,
} from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Validation
import {
  required, alphaNum, email, length, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormFile, BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BFormInvalidFeedback, BFormGroup, BFormTextarea, BFormCheckbox, BPopover, VBToggle, BSpinner, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import purchaseStoreModule from '../purchaseStoreModule'
import PurchaseSidebarAddNewSuplier from '../PurchaseSidebarAddNewSuplier.vue'
import PurchaseSidebarAddNewWarehouse from '../PurchaseSidebarAddNewWarehouse.vue'
import PurchaseSidebarAddNewCar from '../PurchaseSidebarAddNewCar.vue'
import PurchaseSidebarAddNewProduct from '../PurchaseSidebarAddNewProduct.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    // BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // BInputGroup,
    // BInputGroupAppend,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
    BPopover,
    BBadge,
    flatPickr,
    vSelect,
    Logo,
    PurchaseSidebarAddNewSuplier,
    PurchaseSidebarAddNewWarehouse,
    PurchaseSidebarAddNewCar,
    PurchaseSidebarAddNewProduct,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
      length,
      min,
    }
  },
  watch: {
    purchaseData(value) {
      console.log('pp', value)
      // console.log(this.$refs.refFile)
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.purchaseData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    changeHeightInItemForm() {
      this.$refs.form.style.overflow = 'hidden'

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.purchaseData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
  },
  setup() {
    const toast = useToast()
    const PURCHASE_APP_STORE_MODULE_NAME = 'app-purchase'
    const { t } = useI18nUtils()
    const totalWeightActivator = ref(false)
    const refFile = ref(null)

    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, purchaseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME)
    })
    const accounts = ref([])

    store.dispatch('app-purchase/getSubOptions', 'AccountSuppliers')
      .then(response => {
        accounts.value = response.data.records
      })

    // const suppliers = (ctx, callback) => {
    //   store
    //     .dispatch('app-purchase/fetchSupliers')
    //     .then(response => {
    //       const { records } = response.data

    //       callback(records)
    //     })
    //     .catch(() => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Error fetching suppliers' list",
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //         },
    //       })
    //     })
    // }

    const cars = ref([])
    store.dispatch('app-purchase/fetchCars')
      .then(response => {
        cars.value = response.data.records.map(item => {
          const newItem = item
          newItem.name = item.number
          return newItem
        })
      })

    const itemFormBlankItem = {
      product: null,
      cost: null,
      quantity: null,
      weight: null,
      subItems: [
        {
          weight: null,
        },
      ],
      description: '',
    }

    const blankpurchaseData = {
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      supplier: null,
      warehouse: null,
      car: null,
      file_id: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      // salesPerson: null,
      note: '',
      toggler: false,
      extra_costs: {
        kassa_id: null,
        amount: null,
        id: null,
        kassa: null,
        type: null,
      },
      // paymentMethod: null,
    }

    const purchaseData = ref({
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      supplier: null,
      warehouse: null,
      car: null,
      file_id: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      // salesPerson: null,
      note: '',
      toggler: false,
      extra_costs: {
        kassa_id: null,
        amount: null,
        id: null,
        kassa: null,
        type: null,
      },
      // paymentMethod: null,
    })

    const warehouses = ref([])
    store.dispatch('app-purchase/fetchWarehouses')
      .then(response => {
        warehouses.value = response.data.records
        return response.data.records
      })
      .then(response => {
        response.forEach(item => {
          if (item.defaults === '1') {
            purchaseData.value.warehouse = item
          }
        })
      })

    const suppliers = ref([])
    store.dispatch('app-purchase/fetchSupliers')
      .then(response => {
        suppliers.value = response.data.records
        return response.data.records
      })
      .then(response => {
        response.forEach(item => {
          if (item.defaults === '1') {
            purchaseData.value.supplier = item
          }
        })
      })

    const resetPurchaseData = () => {
      purchaseData.value = JSON.parse(JSON.stringify(blankpurchaseData))
    }

    const products = ref([])
    const selectWarehouse = val => {
      console.log(val)
      products.value = []
      store
        .dispatch('app-purchase/getProductsbyWm2', val.id)
        .then(response => {
          const { data } = response
          products.value = data.map(item => {
            const newItem = {
              product: item,
              // itemTitle: `${item.code} ${item.name}`,
              itemTitle: item.name,
              cost: item.init_price,
              quantity: null,
              weight: item.weight,
              subItems: [
                {
                  weight: null,
                },
              ],
              description: item.name,
            }
            return newItem
          })
        })
    }
    selectWarehouse({ id: 0 })
    // store.dispatch('app-purchase/fetchProducts')
    //   .then(response => {
    //     products.value = response.data.records
    //       .map(item => {
    //         const newItem = {
    //           product: item,
    //           // itemTitle: `${item.code} ${item.name}`,
    //           itemTitle: item.name,
    //           cost: item.init_price,
    //           quantity: 0,
    //           weight: 0,
    //           subItems: [
    //             {
    //               weight: 0,
    //             },
    //           ],
    //           description: item.description,
    //         }
    //         return newItem
    //       })
    //   })

    const updateItemForm = (index, val) => {
      console.log(val)
      const {
        cost,
        quantity,
        description,
        subItems,
      } = val
      purchaseData.value.items[index].cost = cost
      purchaseData.value.items[index].quantity = quantity
      purchaseData.value.items[index].weight = null
      purchaseData.value.items[index].subItems = subItems
      purchaseData.value.items[index].description = description

      console.log(purchaseData.value)
    }

    const updateSubItemQty = (index, val) => {
      // console.log(index, val, purchaseData.value.items[index].subItems)
      const arr = []
      for (let i = 0; i < val; i = 1 + i) {
        arr.push({ weight: null })
      }
      purchaseData.value.items[index].subItems = arr
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPurchaseData)

    watchEffect(() => console.log(refFormObserver.value))

    const refSupplierOpt = ref(null)
    const refWarehouseOpt = ref(null)
    const refCarOpt = ref(null)
    const refProductOpt = ref(null)

    const refetchSuppliers = val => {
      suppliers.value.unshift(val)
      refSupplierOpt.value.$emit('input', val)
      // purchaseData.value.supplier = val
      // refSupplierOpt.value.$emit('option:selected', val)
      // setTimeout(() => {
      // refSupplierOpt.value.$emit('close')
      // }, 1000)
      // this.$nextTick(() => {
      //   refSupplierOpt.value.close()
      // })

      // store.dispatch('app-purchase/fetchSupliers')
      //   .then(response => { suppliers.value = response.data.records })
    }

    const refetchWarehouses = val => {
      warehouses.value.unshift(val)
      refWarehouseOpt.value.$emit('input', val)
      // store.dispatch('app-purchase/fetchWarehouses')
      //   .then(response => { warehouses.value = response.data.records })
    }

    const refetchCars = val => {
      const newVal = val
      newVal.name = val.number
      cars.value.unshift(newVal)
      refCarOpt.value.$emit('input', newVal)
      // store.dispatch('app-purchase/fetchCars')
      //   .then(response => {
      //     cars.value = response.data.records.map(item => {
      //       const newItem = item
      //       newItem.name = item.number
      //       return newItem
      //     })
      //   })
    }

    const refetchProducts = val => {
      const newVal = {
        product: val,
        itemTitle: `${val.code} ${val.name}`,
        cost: val.init_price,
        quantity: null,
        weight: null,
        subItems: [
          {
            weight: null,
          },
        ],
        description: val.description,
      }
      products.value.unshift(newVal)
      refProductOpt.value.$emit('input', newVal)

      // store.dispatch('app-purchase/fetchProducts')
      //   .then(response => {
      //     products.value = response.data.records
      //       .map(item => {
      //         const newItem = {
      //           product: item,
      //           itemTitle: item.name,
      //           cost: item.init_price,
      //           quantity: item.quantity,
      //           weight: null,
      //           subItems: [
      //             {
      //               weight: null,
      //             },
      //           ],
      //           description: item.description,
      //         }
      //         return newItem
      //       })
      //   })
    }

    const submitOnProcess = ref({
      save: false,
      saveandsell: false,
      todraft: false,
      file: false,
    })

    const filePath = ref(null)

    const onSubmitSave = () => {
      submitOnProcess.value.save = true
      console.log(purchaseData.value.file_id)
      const payload = JSON.parse(JSON.stringify(purchaseData.value))
      payload.actiontype = 'save'
      payload.status = 'Closed'
      payload.supplier = payload.supplier.id
      payload.warehouse = payload.warehouse.id
      payload.car = payload.car.id ? payload.car.id : payload.car
      payload.items = payload.items.map(item => {
        const newItem = item
        newItem.product = item.product.product.id
        return newItem
      })
      payload.file_id = filePath.value
      payload.totalWeightActivator = totalWeightActivator
      console.log('payload', payload)
      store.dispatch('app-purchase/addPurchase', payload)
        .then(response => {
          console.log(response)
          // const { records } = response.data
          if (response.data.code === 1) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Успешно',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Вы успешно нажали на кнопку сохранить.',
              },
            })
            // resetForm()
            router.push({ name: 'apps-purchase-preview', params: { id: response.data.order_id } })
          } else throw new Error(response.data.status)
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.save = false
        })
    }

    const onSubmitSaveSell = () => {
      submitOnProcess.value.saveandsell = true
      const payload = JSON.parse(JSON.stringify(purchaseData.value))
      payload.actiontype = 'saveandsell'
      payload.status = 'Closed'
      // payload.supplier = payload.supplier.contact
      // payload.warehouse = payload.warehouse.contact
      // payload.car = payload.car.contact
      // payload.items = payload.items.map( item => {
      //   item.product = item.product.contact
      // })

      payload.supplier = payload.supplier.id
      payload.warehouse = payload.warehouse.id
      payload.car = payload.car.id ? payload.car.id : payload.car
      payload.items = payload.items.map(item => {
        const newItem = item
        newItem.product = item.product.product.id
        return newItem
      })
      // console.log(payload)
      payload.file_id = filePath.value
      payload.totalWeightActivator = totalWeightActivator
      store.dispatch('app-purchase/addPurchase', payload)
        .then(response => {
          // console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          resetForm()
          router.push({ name: 'apps-sales-add', params: { tablename: 'purchase', id: response.data.order_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.saveandsell = false
        })
    }

    const onSubmitToDraft = () => {
      submitOnProcess.value.todraft = true
      const payload = JSON.parse(JSON.stringify(purchaseData.value))
      payload.actiontype = 'todraft'
      payload.status = 'Draft'
      // payload.supplier = payload.supplier.contact
      // payload.warehouse = payload.warehouse.contact
      // payload.car = payload.car.contact
      // payload.items = payload.items.map( item => {
      //   item.product = item.product.contact
      // })

      payload.supplier = payload.supplier.id
      payload.warehouse = payload.warehouse.id
      payload.car = payload.car.id ? payload.car.id : payload.car
      payload.items = payload.items.map(item => {
        const newItem = item
        newItem.product = item.product.product.id
        return newItem
      })
      payload.file_id = filePath.value
      console.log(payload)
      store.dispatch('app-purchase/addPurchase', payload)
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          router.push({ name: 'apps-purchase-preview', params: { id: response.data.order_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.todraft = false
        })
    }
    function addZeroes(givnum, deci) {
      let num = Number(givnum)
      if (Number.isNaN(num)) {
        return 0
      }
      if (String(num).split('.').length < deci || String(num).split('.')[1].length <= deci) {
        num = num.toFixed(deci)
      }
      return num
    }
    const onSubmitWeight = index => {
      if (totalWeightActivator.value === false) {
        const temp = purchaseData.value.items[index].subItems.reduce((total, item) => total + parseFloat(item.weight), 0)
        purchaseData.value.items[index].weight = addZeroes(temp, 3)
      }
    }

    const paymentMethods = [
      'Bank Account',
      'Uzcard',
      'Cash',
    ]

    const resolveBalanceColor = value => {
      if (value > 0) return 'success'
      if (value === 0) return 'primary'
      if (value < 0) return 'danger'
      return 'secondary'
    }

    const totalPrice = computed(() => purchaseData.value.items.reduce((total, item) => total + item.weight * item.cost, 0))

    const fileSubmit = val => {
      submitOnProcess.value.file = true
      const formData = new FormData()
      formData.append('file', val)
      store.dispatch('app-purchase/addFile', formData)
        .then(response => {
          filePath.value = response.data.id
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new file',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message || 'upload server error',
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.file = false
        })
    }
    const roundUp = num => {
      const x = num ? (Math.round(num * 10) / 10) : 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    }

    function roundUpCustom(givnum, decimals = 2) {
      if (givnum !== null) {
        const num = Number.isNaN(givnum) ? 0 : givnum
        const tem = 10 ** decimals
        const preval = (
          Math.round(
            num * tem + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / (100 ** decimals)))
          ) / tem
        ).toFixed(decimals)
        const parts = preval.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return parts.join('.')
      }
      return 0
    }

    return {
      roundUpCustom,
      purchaseData,
      suppliers,
      warehouses,
      cars,
      products,
      updateItemForm,
      updateSubItemQty,
      itemFormBlankItem,
      paymentMethods,
      totalWeightActivator,
      t,
      onSubmitWeight,
      onSubmitSave,
      onSubmitSaveSell,
      onSubmitToDraft,
      submitOnProcess,
      refFormObserver,
      getValidationState,
      resetForm,
      refetchSuppliers,
      refetchWarehouses,
      refetchCars,
      refetchProducts,
      refSupplierOpt,
      refWarehouseOpt,
      refCarOpt,
      refProductOpt,
      resolveBalanceColor,
      totalPrice,
      refFile,
      fileSubmit,
      selectWarehouse,
      roundUp,
      accounts,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.purchase-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
